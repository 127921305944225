@import '../../../../styles/basics';

.card-toggle {
  display: flex;
  justify-content: center;
  min-width: 80px;
  height: 80px;
  border-radius: 10px;
  border: 2px $pink-800 solid;
  transition: all 0.3s;
  user-select: none;

  &__label {
    align-self: center;
    color: $pink-800;
  }

  &--active {
    border-color: $pink-1000;
    background-color: $pink-1000;

    .card-toggle__label {
      color: $white;
    }

    .card-toggle__label-description {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        border: solid 15px transparent;
        border-bottom-color: $slate-200;
        z-index: 1;
      }
    }
  }

  &:hover:not(.card-toggle--disabled) {
    cursor: pointer;
    background-color: $pink-800;
    color: $white;

    &.card-toggle--active {
      border-color: $pink-1200;
      background-color: $pink-1200;
    }
  }
}
