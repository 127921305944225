@import '../../../styles/basics';

.switcher {
  &.disabled,
  &.loading {
    opacity: 0.8;
  }

  &.color-pink {
    &:not(.disabled):not(.loading) .switcher-field:not(.active):hover {
      background-color: $pink-1000;
    }

    .switcher-field {
      background-color: $pink-800;

      &.active {
        background-color: $pink-1000;
      }

      path {
        fill: $pink-1000;
        stroke: $pink-1000;
        @include media-breakpoint-up(md) {
          stroke: none;
        }
      }
    }
  }

  &.color-turquoise {
    &:not(.disabled):not(.loading) .switcher-field:not(.active):hover {
      background-color: $turquoise-1200;
    }

    .switcher-field {
      background-color: $turquoise-1000;

      &.active {
        background-color: $turquoise-1200;
      }

      path {
        fill: $turquoise-1200;
        stroke: $turquoise-1200;
        @include media-breakpoint-up(md) {
          stroke: none;
        }
      }
    }
  }

  .switcher-field {
    transition: background-color 0.3s;
    padding: 6px;
    // center and equalize by width each field
    flex: 1 1 0;
    @include media-breakpoint-up(md) {
      padding: 12px;
      width: 50%;
    }

    &.small {
      padding: 6px;
    }

    &:first-child {
      border-top-left-radius: 100px;
      border-bottom-left-radius: 100px;
    }

    &:last-child {
      flex-direction: row-reverse;
      border-top-right-radius: 100px;
      border-bottom-right-radius: 100px;
    }

    > * {
      align-self: center;
    }

    h5,
    :global(.header-three) {
      color: $white;
      margin: auto;
    }

    .indicator {
      border-radius: 100%;
      background-color: $white;
      opacity: 0.2;
      transition: opacity 0.2s;
      width: 30px;
      height: 30px;
      display: flex;
      @include media-breakpoint-up(md) {
        width: 40px;
        height: 40px;
      }

      &.not-active {
        svg {
          display: none;
        }
      }

      svg {
        margin: auto;
      }
    }

    &.active {
      .indicator {
        opacity: 1;
      }
    }
  }

  &:not(.disabled):not(.loading) .switcher-field:not(.active) {
    cursor: pointer;

    &:hover {
      .indicator {
        opacity: 0.3;
      }
    }
  }
}
