@import '../../../../styles/basics';

.field-error {
  color: $red-1000;
  font-size: 14px;
  margin-top: 6px;
  animation: append-animate-field-error .5s linear;
}

// Avoid sudden jumps when an error appears.
// The jumps might cancel a click on an element that moves.
@keyframes append-animate-field-error {
  from {
    margin-bottom: -24px;
  }
  to {
    margin-bottom: 0;
  }
}
