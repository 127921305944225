@import '../../../styles/basics';

.formatted-text {
  a[href]:not([data-component^="button"]),
  a[href] > p {
    text-decoration: underline;
    &:hover {
      color: $turquoise-1200;
      text-decoration-thickness: 3px;
    }
  }
}