@import '../../../../styles/basics';

.sigCanvas {
  margin-top: 10px;
  border: 1px solid $black;
}
.clearCanvas {
  margin-top: 10px;
  display: block;
}
