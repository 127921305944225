@import '../../../../styles/basics';

.icon-toggle {
  position: relative;

  &__inner {
    height: 75px;
    width: 75px;
    border: solid 1px $slate-200;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
  }

  &--active {
    .icon-toggle__inner {
      background-color: $slate-1200;
    }
  }

  &:not(.icon-toggle--disabled) {
    cursor: pointer;
  }

  @include media-breakpoint-up(md) {
    &__inner {
      padding: 10px;
      height: 40px;
      width: auto;
      min-width: 101px;
    }
  }
}
