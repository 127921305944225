@import '../../../../styles/basics';

.field-type-radios {
  margin-bottom: 24px;

  &--error {
    // Add vertical red line.
    border-left: 2px solid $red-1000;
    padding-left: 10px;
  }

  &__label {
    margin-bottom: 16px;
    font-size: 16px;
    font-weight: $font-weight-bold;

    &-validation {
      font-size: 14px;
      color: $slate-1000;
      font-weight: $font-weight-regular;
    }
  }

  [data-component="radios_values"] {
    .field-type-radios__item {
      display: inline-block;
      margin-right: 24px;
    }
  }

  &__item {
    position: relative;
    margin-bottom: 0;

    &:last-child {
      margin-bottom: 0;
    }

    &-label {
      cursor: pointer;
      margin: 12px 0;
      display: inline-block;
      line-height: 24px;
      font-size: 16px;
      transition: 0.3s border;

      &-label {
        margin-left: 36px;
      }
    }

    &-input {
      display: none;
    }

    &-indicator {
      background: $white;
      height: 24px;
      width: 24px;
      position: absolute;
      left: 0;
      top: 12px;
      border: 1px solid $gray-1000;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      border-radius: 50%;
      transition: 0.2s all;
    }

    &-label:hover &-indicator {
      border-color: $turquoise-1000;
    }

    &--selected &-label {
      color: $turquoise-1000;
      border-color: $turquoise-1000;
    }

    &--selected &-indicator {
      border: 8px solid $turquoise-1000;
    }

    &--selected path[fill='#767676'] {
      fill: $turquoise-1000;
    }
  }
}
