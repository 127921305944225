@import '../../../../styles/basics';

.field-type-radios-button {
  margin-bottom: 24px;

  & > &__label {
    margin-bottom: 16px;
    font-size: 16px;
  }

  &__radios {
    margin: -8px -10px;

    &-item {
      margin: 8px 10px;
      display: inline-block;
      background: $white;
      transition: background-color 0.2s, border-color 0.2s;
      color: $slate-1000;
      border: 1px solid $slate-200;
      border-radius: 4px;
      padding: 6px;
      font-size: 16px;
      max-width: 220px;
      text-align: center;
      cursor: pointer;

      &--large {
        // An exception to fit very small screens.
        padding: 13px 10px;

        // Normal style.
        @media (min-width: 360px) {
          font-size: 18px;
          padding: 13px;
        }
      }

      &-input {
        display: none;
      }

      &--selected {
        color: $white;
        background-color: $turquoise-1200;
        border: 1px solid $turquoise-1200;
      }

      &--media-body {
        flex: 1 1;
      }

      &:not(&--selected):hover {
        background-color: $slate-200;
      }
    }
  }

  &--emergency {
    .field-type-radios-button__radios-item--selected {
      background-color: $red-800;
      border: 1px solid $red-800;
    }
  }

  &--emergency-black {
    .field-type-radios-button__radios-item--selected {
      background-color: $black;
      border: 1px solid $black;
    }
  }
}
