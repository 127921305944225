@import '../../../../styles/basics';
@value tooltip from '../../Tooltip/index.module.scss';

.field {
  margin-bottom: 32px;
  position: relative;
  transition: all 0.3s;
  line-height: $line-height-small;

  &__label {
    position: relative;
    width: 100%;
    display: block;

    .tooltip {
      position: absolute;
      top: 0;
      right: 0;
      width: 15px;
      height: 15px;

      :global(.tooltip__content) {
        display: flex;
      }

      :global(.question-icon) {
        path {
          fill: $turquoise-1000;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  &__right-icon-container {
    position: absolute;
    right: 4px;
    top: 3px;
    width: 40px;
    height: 40px;

    &__error-icon {
      width: 40px;
      height: 40px;
      padding: 8px;

      path {
        fill: $red-1000;
      }
    }

    &__checkmark-icon {
      width: 40px;
      height: 40px;
      padding: 10px;

      path {
        fill: $turquoise-1000;
      }
    }
  }

  &__text-icon {
    position: absolute;
    right: 40px;
    top: 14px;
    font-size: 14px;
    font-weight: $font-weight-bold;
    text-align: right;
    color: $slate-600;
  }

  &:global(.error) {
    // Add vertical red line.
    border-left: 2px solid $red-1000;
    padding-left: 10px;
  }
}
