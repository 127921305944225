@import '../../../styles/basics';

.tooltip {
  :global(.react-tooltip) {
    font-weight: $font-weight-regular;
    font-size: 14px;
    line-height: $line-height-normal;
    padding: 8px;
    max-width: 225px;
    z-index: 1;
    background: $turquoise-1000;
    opacity: 1;
  }
}
